// React
import React, {useState, useEffect} from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Card, Box } from '@mui/material';

// Internal
import CreateLineDialog from './createLineDialog';

// Third-party
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');


const VM_TYPES = {
  input: 'Ativo',
  both: 'Receptivo',
  extreme: 'Extrema'
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LinesDialog({open, setOpen, appUser}) {
  const handleClose = () => {
    setOpen();
  };

  const [vmsRequest, setVmsRequest] = useState([]);
  const [openCreateVMDialog, setOpenCreateVMDialog] = useState(false);

  const handleGetInfo = (id) => {
    if (id) {
      axios({
        method: 'get',
        url:`https://interface.clickservices.com.br/auth/admin/company/vms?token=${appUser?.token}&id=${id}`,
        responseType: 'json',
      })
      .then(response => {
        if (response.status === 200) {
          let newVMSOrganization = response?.data?.vms ?? null;
          if (newVMSOrganization) {
            newVMSOrganization = Object.entries(newVMSOrganization).map(([key, value]) => {
              value.name = key;
              return value;
            })
          }
          else {
            newVMSOrganization = []
          }
          
          setVmsRequest(newVMSOrganization)
          // setUsersCountingRequest(response.data.usersCounting)
        }
      })
      .catch(console)
      .finally(el => {
      })
    }
  }   

  useEffect(() => {
    if (!open) {
      setVmsRequest([])
    }
    else {
      handleGetInfo(open?.id);
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (!openCreateVMDialog) {
      handleGetInfo(open?.id);
    }
    // eslint-disable-next-line
  }, [openCreateVMDialog])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Ambiente: {open?.name}
          </Typography>
          <Button autoFocus color="inherit" onClick={() => setOpenCreateVMDialog(true)}>
            CRIAR FILA
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        sx={{
          justifyContent: 'space-evenly',
          alignItems: 'space-evenly',
          marginTop: '1rem',
          height: '100vh',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          flexWrap: 'wrap',
        }}
      >
        {
            vmsRequest.map((el, index) => {
              return (
                  <Card
                    sx={{
                      width: '500px',
                      height: '150px',
                      marginBottom: '1rem',
                      paddingLeft: '1rem',
                      paddingTop: '1rem',
                      backgroundColor: el?.active ? '#81c784' :'#e57373',
                    }}
                    key={`${index}-companies-card`}
                  >
                    <Box
                      display="flex"
                      sx={{
                        flexDirection: 'column'
                      }}
                    >
                      <Typography>
                        Nome: {el?.name}
                      </Typography>
                      <Typography>
                        {/* Quantidade de Operadores Ativos: {usersCountingRequest?.find(_el => 
                          _el?._id?.worker === 'operator' && _el?._id?.public_key_company === el.public_key
                        )?.count ?? 0}/{el.quantities.operators} */}
                        Tipo: {VM_TYPES[el?.type]}
                      </Typography>
                      <Typography>
                        {/* Quantidade de Supervisores Ativos: {usersCountingRequest?.find(_el => 
                          _el?._id?.worker === 'supervisor' && _el?._id?.public_key_company === el.public_key
                        )?.count ?? 0}/{el.quantities.supervisors} */}
                      </Typography>
                    </Box>
                  </Card>
              )
            })
          }
      </Box>
      <CreateLineDialog 
        open={openCreateVMDialog}
        setOpen={setOpenCreateVMDialog}
        id={open?.id}
      />
    </Dialog>
  );
}
