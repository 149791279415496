// React
import React, { useState, useEffect } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Card, Box, Tooltip, CardHeader, Stack } from '@mui/material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import AirplanemodeInactiveIcon from '@mui/icons-material/AirplanemodeInactive';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Internal
import CreateEnvironmentDialog from './createEnvironmentDialog';
import LinesDialog from './linesDialog';

// Third-party
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HolderDialog({ cnpj, holder_name, open, setOpen, appUser }) {

  const handleClose = () => {
    setOpen(false);
  };

  const [companiesRequest, setCompaniesRequest] = useState([]);
  const [usersCountingRequest, setUsersCountingRequest] = useState([]);
  const [dropletsRequest, setDropletsRequest] = useState([]);
  const [openCreateEnvironmentDialog, setOpenCreateEnvironmentDialog] = useState(false);
  const [openLinesDialog, setOpenLinesDialog] = useState(null);

  const handleGetInfo = (cnpj) => {
    axios({
      method: 'get',
      url: `https://interface.clickservices.com.br/auth/admin/company/companies?token=${appUser.token}&cnpj=${cnpj}`,
      responseType: 'json',
    })
      .then(response => {
        if (response.status === 200) {
          setCompaniesRequest(response.data.companies);
          setUsersCountingRequest(response.data.usersCounting);
          setDropletsRequest(response.data.droplets);
          console.log({ response })
        }
      })
      .catch(console)
      .finally(el => {
      })
  }

  const handleStatusEnvironment = ({ environmentId, status }) => {
    let data = {
      environmentId,
      status
    }
    axios({
      method: 'put',
      url: `https://interface.clickservices.com.br/auth/admin/company/status?token=${appUser.token}`,
      responseType: 'json',
      data
    })
      .then(response => {
        if (response.status === 200) {
        }
      })
      .catch(console)
      .finally(el => {
        handleGetInfo(cnpj);
      })
  }

  const handleDestroyDroplet = ({ environmentId }) => {
    let data = {
      environmentId
    }
    axios({
      method: 'delete',
      url: `https://interface.clickservices.com.br/auth/admin/droplets/destroy?token=${appUser.token}`,
      responseType: 'json',
      data
    })
      .then(response => {
        if (response.status === 200) {
        }
      })
      .catch(console)
      .finally(el => {
        handleGetInfo(cnpj);
      })
  }


  useEffect(() => {
    if (!open) {
      setCompaniesRequest([])
    }
    else {
      handleGetInfo(cnpj);
    }
    // eslint-disable-next-line
  }, [open])

  useEffect(() => {
    if (!openCreateEnvironmentDialog) {
      handleGetInfo(cnpj);
    }
    // eslint-disable-next-line
  }, [openCreateEnvironmentDialog])

  useEffect(() => {
    if (!open) {
      setOpenLinesDialog(null)
    }
    // eslint-disable-next-line
  }, [openLinesDialog])

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Empresa: {holder_name}
          </Typography>
          <Button autoFocus color="inherit" onClick={() => setOpenCreateEnvironmentDialog(true)}>
            CRIAR AMBIENTE
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        display="flex"
        sx={{
          justifyContent: 'space-evenly',
          alignItems: 'space-evenly',
          marginTop: '1rem',
          height: '100vh',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          flexWrap: 'wrap',
        }}
      >
        {
          companiesRequest.map((el, index) => {
            return (
              <Card
                sx={{
                  width: '500px',
                  height: '230px',
                  marginBottom: '1rem',
                  paddingLeft: '1rem',
                  paddingTop: '1rem',
                  backgroundColor: el?.active ? '#81c784' : '#e57373',
                }}
                key={`${index}-companies-card`}
              >
                <CardHeader
                  action={
                    el?.active ?
                      <Tooltip title="Desativar ambiente">
                        <IconButton
                          onClick={() => handleStatusEnvironment({ environmentId: el?._id, status: false })}
                        >
                          <AirplanemodeActiveIcon />
                        </IconButton>
                      </Tooltip>
                      :
                      <Tooltip title="Ativar Ambiente">
                        <IconButton
                          onClick={() => handleStatusEnvironment({ environmentId: el?._id, status: true })}
                        >
                          <AirplanemodeInactiveIcon />
                        </IconButton>
                      </Tooltip>
                  }
                />
                <Box
                  display="flex"
                  sx={{
                    flexDirection: 'column'
                  }}
                >
                  <Typography>
                    ID: {el?._id}
                  </Typography>
                  <Typography>
                    Nome: {el.company_name}
                  </Typography>
                  <Typography>
                    Quantidade de Operadores Ativos: {usersCountingRequest?.find(_el =>
                      _el?._id?.worker === 'operator' && _el?._id?.public_key_company === el.public_key
                    )?.count ?? 0}/{el.quantities.operators}
                  </Typography>
                  <Typography>
                    Quantidade de Supervisores Ativos: {usersCountingRequest?.find(_el =>
                      _el?._id?.worker === 'supervisor' && _el?._id?.public_key_company === el.public_key
                    )?.count ?? 0}/{el.quantities.supervisors}
                  </Typography>
                  {
                    el.active &&
                    <Stack
                      direction="row"
                      spacing={1}
                    >
                      <Button variant="outlined" color="secondary" onClick={() => setOpenLinesDialog({ id: el._id, name: el.company_name })}>
                        Filas
                      </Button>
                      <Button variant="outlined" color="secondary">
                        Apps
                      </Button>
                    </Stack>
                  }
                  {
                    !el.active && dropletsRequest?.filter(_el => [`${String(el._id)}-files`, String(el._id)].includes(_el.hostname))?.length > 0 &&

                    <Tooltip title="Destruir Droplets">
                      <IconButton
                        onClick={() => handleDestroyDroplet({ environmentId: el?._id })}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </Box>
              </Card>
            )
          })
        }
      </Box>
      <CreateEnvironmentDialog
        open={openCreateEnvironmentDialog}
        setOpen={setOpenCreateEnvironmentDialog}
        cnpj={cnpj}
      />
      <LinesDialog
        open={openLinesDialog}
        setOpen={setOpenLinesDialog}
        appUser={appUser}
      />
    </Dialog>
  );
}
