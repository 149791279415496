// React
import React, { useEffect, useState } from "react";

// Design
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Stack, Tooltip } from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplanemodeInactiveIcon from "@mui/icons-material/AirplanemodeInactive";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import QrCodeIcon from "@mui/icons-material/QrCode";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import MobileOffIcon from "@mui/icons-material/MobileOff";

// Internal
import { appUser } from "../../../store/app/slice";

// Third-party
import axios from "axios";
import { useSelector } from "react-redux";

const SPEED = {
  5: 5,
  10: 10,
  20: 20,
  30: 30,
  40: 40,
  50: 50,
  60: 60,
  70: 70,
  80: 80,
  90: 90,
  100: 100,
  110: 110,
  120: 120,
  130: 130,
  140: 140,
  150: 150,
  160: 160,
  170: 170,
  180: 180,
  190: 190,
  200: 200,
};

export default function VMEditDialog({ open, setOpen, vm }) {
  const _appUser = useSelector(appUser);
  const [lineSpeed, setLineSpeed] = useState("");
  const [ddi, setDDI] = useState(vm?.phone?.substring(0, 2) ?? "");
  const [ddd, setDDD] = useState(vm?.phone?.substring(2, 4) ?? "");
  const [phone, setPhone] = useState(vm?.phone?.substring(4, 15) ?? "");
  const [blockCreation, setBlockCreation] = useState(true);
  console.log({ vm });

  const handleClose = () => {
    setOpen(false);
  };

  const handleRestartVM = (data) => {
    axios({
      method: "post",
      url: `https://interface.clickservices.com.br/auth/admin/droplets/vm/reboot?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        setOpen(false);
      });
  };

  const handleRebuildVM = (data) => {
    setOpen(false);

    axios({
      method: "post",
      url: `https://interface.clickservices.com.br/auth/admin/droplets/vm/rebuild?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {});
  };

  const handleChangeLineSpeed = (event, data) => {
    setLineSpeed(event.target.value);
    axios({
      method: "put",
      url: `https://interface.clickservices.com.br/auth/admin/vms/change-line-speed?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
        line_speed: event.target.value,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        setOpen(false);
      });
  };

  const copyQRCode = (data) => {
    navigator.clipboard.writeText(
      `https://interface.clickservices.com.br/qr-code?vm=${data.id}&company_id=${data.company_id}`
    );
  };

  const handleCreateVM = (data) => {
    setBlockCreation(true);
    axios({
      method: "post",
      url: `https://interface.clickservices.com.br/auth/admin/droplets/vm/create?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
        phone_number: phone,
        ddd,
        ddi,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        setOpen(false);
      });
  };

  const handleActivateVM = (data) => {
    axios({
      method: "put",
      url: `https://interface.clickservices.com.br/auth/admin/vms/activate?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        setOpen(false);
      });
  };

  const handleEraseLines = (data) => {
    axios({
      method: "delete",
      url: `https://interface.clickservices.com.br/auth/admin/vms/lines?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        setOpen(false);
      });
  };

  const handleDestroyVM = (data) => {
    axios({
      method: "delete",
      url: `https://interface.clickservices.com.br/auth/admin/droplets/vm/destroy?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((_) => {
        setOpen(false);
      });
  };

  const handleDeactivateVM = (data) => {
    axios({
      method: "put",
      url: `https://interface.clickservices.com.br/auth/admin/vms/deactivate?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        // handleEraseLines(data)
        // setOpen(false)
        handleDestroyVM(data);
      });
  };

  const handleEraseMessages = (data) => {
    axios({
      method: "put",
      url: `https://interface.clickservices.com.br/auth/admin/vms/erase-messages?token=${_appUser.token}`,
      responseType: "json",
      data: {
        vm: data.id,
        company_id: data.company_id,
        status: !data._eraseMessages,
      },
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        setOpen(false);
      });
  };

  useEffect(() => {
    if (ddi && ddd && phone) {
      setBlockCreation(false);
    }
  }, [ddi, ddd, phone]);

  useEffect(() => {
    if (open) {
      setLineSpeed(SPEED[vm.line_speed]);
    } else {
      setLineSpeed("");
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              display: "flex",
              height: "12rem",
              width: "35rem",
              // alignItems: 'flex-start',
              justifyContent: "flex-start",
              margin: "0 0 2rem 0",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-start",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              Informações da VM ({vm.id})
            </Box>
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-end",
              }}
            >
              <Stack direction="row">
                <Tooltip title="Reiniciar VM" placement="top">
                  <span>
                    <IconButton
                      size="medium"
                      onClick={() => handleRestartVM(vm)}
                    >
                      <RestartAltIcon fontSize="inherit" color="error" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Zerar Fila" placement="top">
                  <span>
                    <IconButton
                      size="medium"
                      onClick={() => handleEraseLines(vm)}
                      edge="start"
                    >
                      <DirectionsRunIcon fontSize="inherit" color="error" />
                    </IconButton>
                  </span>
                </Tooltip>
                {vm?._active ? (
                  <Tooltip title="Desativar VM" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => handleDeactivateVM(vm)}
                        edge="start"
                      >
                        <AirplanemodeInactiveIcon
                          fontSize="inherit"
                          color="error"
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Ativar VM" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => handleActivateVM(vm)}
                        edge="start"
                      >
                        <AirplanemodeActiveIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {vm?._eraseMessages ? (
                  <Tooltip title="Manter Mensagens no Celular" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => handleEraseMessages(vm)}
                        edge="start"
                      >
                        <MobileFriendlyIcon
                          fontSize="inherit"
                          color="primary"
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip title="Apagar Mensagens do Celular" placement="top">
                    <span>
                      <IconButton
                        size="medium"
                        onClick={() => handleEraseMessages(vm)}
                        edge="start"
                      >
                        <MobileOffIcon fontSize="inherit" color="error" />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
                {vm?.latest_vm_version !== vm?.version &&
                  vm?.status_droplet !== "Não existe" && (
                    <Tooltip title="Atualizar VM" placement="top">
                      <span>
                        <IconButton
                          size="medium"
                          onClick={() => handleRebuildVM(vm)}
                          edge="start"
                        >
                          <SystemUpdateAltIcon
                            fontSize="inherit"
                            color="primary"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}

                <Tooltip title="Copiar link qr code" placement="top">
                  <span>
                    <IconButton
                      size="medium"
                      onClick={() => copyQRCode(vm)}
                      edge="start"
                    >
                      <QrCodeIcon fontSize="inherit" color="primary" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Velocidade da Fila
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={lineSpeed}
                      label="Velocidade da Fila"
                      onChange={(event) => handleChangeLineSpeed(event, vm)}
                    >
                      {Object.entries(SPEED).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {" "}
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginLeft: "1rem",
                  }}
                >
                  <Button
                    variant="outlined"
                    disabled={!vm?._active}
                    onClick={() => handleDestroyVM(vm)}
                    color="error"
                  >
                    Destruir vm
                  </Button>
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifySelf: "flex-end",
                marginTop: "1rem",
              }}
            >
              <TextField
                id="outlined-basic"
                label="DDI"
                variant="outlined"
                value={ddi}
                onChange={(event) => setDDI(event.target.value)}
                inputProps={{
                  maxLength: 2,
                }}
              />
              <TextField
                id="outlined-basic"
                label="DDD"
                variant="outlined"
                value={ddd}
                onChange={(event) => setDDD(event.target.value)}
                inputProps={{
                  maxLength: 2,
                }}
              />
              <TextField
                id="outlined-basic"
                label="Telefone"
                variant="outlined"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                inputProps={{
                  maxLength: 9,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                disabled={blockCreation}
                onClick={() => handleCreateVM(vm)}
              >
                CRIAR VM
              </Button>
            </Box>
          </Box>
        </DialogTitle>
      </Dialog>
    </div>
  );
}
