// React
import React, { useEffect, useState, useRef } from "react";

// Design
import { Box, Card, Typography } from "@mui/material";

// Internal
import MonitorBar from "../../components/MonitorBar";
import { appUser } from "../../store/app/slice";

// Third-party
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export default function DropletsHealth() {
  // eslint-disable-next-line
  const _appUser = useSelector(appUser);
  const [dropletsRequest, _setDropletsRequest] = useState([]);
  const dropletsRequestRef = useRef([]);
  const setDropletsRequest = (newValue) => {
    _setDropletsRequest(newValue);
    dropletsRequestRef.current = newValue;
  };

  const TIME_TO_UPDATE = 60000 * 1 * 15;

  const handleGetInfo = () => {
    axios({
      method: "get",
      url: `https://interface.clickservices.com.br/auth/admin/droplets/health?token=${_appUser.token}`,
      responseType: "json",
    })
      .then((response) => {
        if (response.status === 200) {
          setDropletsRequest(response.data.statusDroplets);
        }
      })
      .catch(console)
      .finally((el) => {});
  };

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      display="flex"
      sx={{
        height: "100vh",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Box>
        <MonitorBar />
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: "space-evenly",
          alignItems: "space-evenly",
          marginTop: "1rem",
          height: "100vh",
          width: "100%",
          overflowX: "hidden",
          overflowY: "auto",
          flexWrap: "wrap",
        }}
      >
        {dropletsRequest.map((el, index) => {
          return (
            <Card
              sx={{
                width: "400px",
                height: "150px",
                marginBottom: "1rem",
                paddingLeft: "1rem",
                paddingTop: "1rem",
                backgroundColor:
                  parseInt(el.disk_usage_used_gb) /
                    parseInt(el.disk_usage_total_gb) >
                  0.9
                    ? "#e57373"
                    : parseInt(el.disk_usage_used_gb) /
                        parseInt(el.disk_usage_total_gb) >
                      0.5
                    ? "#ffb74d"
                    : "#81c784",
              }}
            >
              <Typography>Hostname: {el.hostname}</Typography>
              <Typography>
                Disco (Gb): {el.disk_usage_used_gb}/{el.disk_usage_total_gb}
              </Typography>
              <Typography>Memória RAM (%): {el.ram_usage_percent}</Typography>
              <Typography>Dia: {el.timestamp}</Typography>
              {!["vm-whats-db", "secure-db"].includes(el.hostname) && (
                <Typography>
                  Conexão com o banco:{" "}
                  <span
                    style={{
                      backgroundColor: "#00000080",
                    }}
                  >
                    {el.db_connection ? "😊" : "😔"}
                  </span>
                </Typography>
              )}
            </Card>
          );
        })}
      </Box>
    </Box>
  );
}
