// React
import React, { useEffect, useState, useRef } from 'react';

// Design
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

// Internal
import { appUser } from '../../store/app/slice';

// Third-party
import axios from 'axios';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';
import MonitorBar from '../../components/MonitorBar';
moment.locale('pt-br');

const columns = [
  { 
    field: 'event_time', 
    headerName: 'Hora de atualização', 
    width: 250, 
    editable: false,
    sortable: true 
  },
  {
    field: 'type',
    headerName: 'Tipo',
    width: 200,
    editable: false,
    sortable: false 
  },
  {
    field: 'script',
    headerName: 'Script',
    width: 300,
    editable: false,
    sortable: false 
  },
  {
    field: 'execution_time',
    headerName: 'Tempo de execução',
    width: 300,
    editable: false,
    sortable: false 
  },
];

export default function StatusServicesTable() {
  // eslint-disable-next-line
  const _appUser = useSelector(appUser);
  // eslint-disable-next-line
  const [page, setPage] = React.useState(0);
  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [servicesListRequest, _setServicesListRequest] = useState([]);
  const servicesListRequestRef = useRef([]);
  const getNewInfo = useRef(true);
  const setServicesListRequest = (newValue) => {
    _setServicesListRequest(newValue);
    servicesListRequestRef.current = newValue;
  }

  const TIME_TO_UPDATE = 3000;

  const actions = [
    { icon: <RestartAltIcon />, name: 'Restart Services', onclick: () => handleRestart() },
  ];
  
  const handleRestart = () => {
    setOpenSpeedDial(false)
      axios({
        method: 'post',
        url:`https://interface.clickservices.com.br/auth/admin/services?token=${_appUser.token}`,
        responseType: 'json',
        })
      .then(response => {
        console.log({response})
      })
      .catch(err=>{
        console.log(err)
      })
  }

  const handleGetInfo = () => {
    if (getNewInfo.current) {
      getNewInfo.current = false;
      axios({
        method: 'get',
        url:`https://interface.clickservices.com.br/auth/admin/services?token=${_appUser.token}`,
        responseType: 'json',
        })
      .then(response => {
        console.log({response})
          if (response.status === 200 && response.data) {
            console.log({response})
            let filteredServices = response.data?.services?.map((el, count) => {
              let execution_time = el.execution_time ?? 0;
              if (execution_time > 86400000) {
                execution_time = `${(execution_time / 86400000).toFixed(0)} d`
              }else if (execution_time >= 3600000) {
                execution_time = `${(execution_time / 3600000).toFixed(0)} h`
              }
              else if (execution_time >= 60000) {
                execution_time = `${(execution_time / 60000).toFixed(0)} m`
              }
              else if (execution_time >= 1000) {
                execution_time = `${(execution_time / 1000).toFixed(0)} s`
              }
              else {
                execution_time = `${execution_time} ms`
              }
              return {
                // 'id': `${el.company_id}-${el.identifier}`,
                'id': count,
                'event_time': el?.event_time ? moment(el.event_time).fromNow() : '',
                'type': el?.TYPE ?? '',
                'script': el?.SCRIPT ?? '',
                'execution_time':execution_time,
              }
            })
            setServicesListRequest(filteredServices || []);
          }
          else {
          //   setChatsListRequest([])
          }
      })
      .catch(err=>{
        console.log(err)
      })
      .finally(() => {
        getNewInfo.current = true;
      })
    }
  }
  
  useEffect(() => {
    handleGetInfo();
    const interval =  setInterval(() => {
                                        handleGetInfo();
                                      }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return (
      <Box
        display="flex"
        sx={{ 
          height: '100vh',
          width: '100%',
          flexDirection: 'column'
        }}
      >
        <Box>
          <MonitorBar />
        </Box>
        <Box
          display='flex'
          sx={{
            height: '92vh',
            width: '100%',
          }}
        >
          <DataGrid
            rows={servicesListRequest}
            columns={columns}
            pageSize={30}
            rowsPerPageOptions={[30]}
            // disableSelectionOnClick
            getRowClassName={(params) => `super-app-theme--${params.row.lineStatus}`}
          />
        </Box>
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          sx={{ position: 'absolute', bottom: 5, left: 16 }}
          icon={<SpeedDialIcon />}
          direction='right'
          open={openSpeedDial}
          onClick={() => setOpenSpeedDial(!openSpeedDial)}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={action.onclick}
            />
          ))}
        </SpeedDial>
      </Box>
  );
}
