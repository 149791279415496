// React
import React, { useState, Fragment, useEffect } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { TextField, Stack, Box } from '@mui/material';

// Internal
import fileTob64 from '../../../utils/functions/filetob64';
import { appUser } from '../../../store/app/slice';

// Third-party
import { useSelector } from 'react-redux';
import axios from 'axios';
import DropZone from "react-dropzone";
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');


export default function CreateHolderDialog({ open, setOpen, }) {

  const _appUser = useSelector(appUser);
  const [cnpj, setCnpj] = useState('');
  const [name, setName] = useState('');
  const [logoName, setLogoName] = useState('');
  const [logo, setLogo] = useState('');
  const [tiUser, setTiUser] = useState('');
  const [tiPass, setTiPass] = useState('');


  const handleClose = () => {
    setOpen(false);
  };

  const onDropFile = (acceptedFiles, rejectedFiles) => {
    console.log('onDropFile', {acceptedFiles, rejectedFiles});
    acceptedFiles.forEach((file) => {
      file.filePromise = fileTob64(file);
      file.filePromise.then(result => {
                // dispatch or do whatever you need with result
                let base64 = result.split(',')[1];
                setLogoName(file.name)
                setLogo(base64)
              })
    })
  };

  const handleCreateHolder = () => {
    const holderObj = {
      cnpj,
      name,
      logo,
      tiUser,
      tiPass
    }
    axios({
      method: 'post',
      url:`https://interface.clickservices.com.br/auth/admin/holding?token=${_appUser.token}`,
      responseType: 'json',
      data: holderObj
    })
    .then(_response => {})
    .catch(console)
    .finally(el => {
      handleClose()
    })
    // console.log({holderObj})
  }

  const onChangeFile = (e) => {
    // console.log('onChangeFile', e);
  };


  useEffect(() => {
    if (!open) {
      setCnpj('');
      setName('');
      setLogoName('');
      setLogo('');
      setTiUser('');
      setTiPass('');
    }
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: 'center',
          width: '400px'
        }}
      >
        <Box
          sx={{
            width: '380px'
          }}
        >
          <Typography textAlign={'center'} textTransform='uppercase'>
            Criar Empresa
          </Typography>
          <Stack  direction="column" spacing={2} >
            <TextField 
              id="cnpj" 
              label="CNPJ" 
              variant="outlined"
              inputProps={{ maxLength: 14 }}
              value={cnpj}
              onChange={(e) => setCnpj(e.target.value)}
            />
            <TextField 
              id="name" 
              label="Nome da Empresa" 
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box>
              <DropZone
                accept="image/png, image/jpeg"
                id="logo"
                onDrop={onDropFile}
                onChange={onChangeFile}
              >
                {({getRootProps, isDragActive}) => (
                  <Fragment>
                    <div
                      style={{
                        height: '10px ',
                        width: '100%',
                        background: 'rgba(0, 0, 0, 0)',
                        opacity: '0.8',
                        textAlign: 'center',
                        // marginTop: '25%'
                      }}
                      {...getRootProps()}
                    >
                      {logoName || 'Solte o logo...'}
                    </div>
                    {/* <DragActiveModal open={isDragActive}/> */}
                  </Fragment>
                )}
              </DropZone>
            </Box>
            <TextField 
              id="ti_user" 
              label="Usuário de T.I."
              type="email"
              variant="outlined"
              value={tiUser}
              onChange={(e) => setTiUser(e.target.value)}
            />
            <TextField 
              id="pass" 
              label="Senha do Usuário de T.I."
              type="password"
              variant="outlined"
              value={tiPass}
              onChange={(e) => setTiPass(e.target.value)}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: 'flex-end'
            }}
          >
            <Stack direction="row">
              <Button color='error' onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                disabled={!cnpj || !name || !tiPass || !tiUser || !logo}
                onClick={handleCreateHolder}
              >
                Criar
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
