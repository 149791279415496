// React
import React, { useEffect, useState, useRef } from 'react';

// Design
import { Box, Card, Typography } from '@mui/material';

// Internal
import MonitorBar from '../../components/MonitorBar';
import { appUser } from '../../store/app/slice';
import HolderDialog from './components/holderDialog';
import CreateHolderDialog from './components/createHolderDialog';

// Third-party
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


export default function Holders() {
  // eslint-disable-next-line
  const _appUser = useSelector(appUser);
  const [holdersRequest, _setHoldersRequest] = useState([]);
  const holdersRequestRef = useRef([]);
  const setHoldersRequest = (newValue) => {
    _setHoldersRequest(newValue);
    holdersRequestRef.current = newValue;
  }

  const [openHolderDialog, setOpenHolderDialog] = useState(false);
  const [opencreateHolderDialog, setOpenCreateHolderDialog] = useState(false);

  const [selectedHolderName, setSelectedHolderName] = useState('');
  const [selectedCNPJ, setSelectedCNPJ] = useState('');

  const TIME_TO_UPDATE = 60000 * 1 * 15;

  const handleGetInfo = () => {
    axios({
      method: 'get',
      url: `https://interface.clickservices.com.br/auth/admin/monitor/holders?token=${_appUser.token}`,
      responseType: 'json',
    })
      .then(response => {
        if (response.status === 200) {
          setHoldersRequest(response.data.holders)
        }
      })
      .catch(console)
      .finally(el => {
      })
  };

  const handleSelectHolder = (name, cnpj) => {
    setSelectedHolderName(name);
    setSelectedCNPJ(cnpj);
    setOpenHolderDialog(true)
  }

  useEffect(() => {
    handleGetInfo();
    const interval = setInterval(() => {
      handleGetInfo();
    }, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!openHolderDialog) {
      setSelectedHolderName('');
      setSelectedCNPJ('');
      handleGetInfo();
    }
    // eslint-disable-next-line
  }, [openHolderDialog])

  return (
    <Box
      display="flex"
      sx={{
        height: '100vh',
        width: '100%',
        flexDirection: 'column'
      }}
    >
      <Box>
        <MonitorBar />
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: 'space-evenly',
          alignItems: 'space-evenly',
          marginTop: '1rem',
          height: '100vh',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          flexWrap: 'wrap',
        }}
      >
        {
          holdersRequest.map((el, index) => {
            return (
              <Card
                sx={{
                  width: '330px',
                  height: '125px',
                  marginBottom: '1rem',
                  paddingLeft: '1rem',
                  paddingTop: '1rem',
                  cursor: 'pointer',
                }}
                onClick={() => handleSelectHolder(el.holder_name, el.cnpj)}
              >
                <Typography>
                  CNPJ: {el.cnpj}
                </Typography>
                <Typography>
                  Nome: {el.holder_name}
                </Typography>
                <Typography>
                  Usuário T.I.: {el.it_user}
                </Typography>
              </Card>
            )
          })
        }
        <Card
          sx={{
            width: '330px',
            height: '125px',
            marginBottom: '1rem',
            paddingLeft: '1rem',
            paddingTop: '1rem',
            cursor: 'pointer',
          }}
          onClick={() => setOpenCreateHolderDialog(true)}
        >
          <Typography>
            + Criar Empresa
          </Typography>
        </Card>
      </Box>
      <HolderDialog
        open={openHolderDialog}
        setOpen={setOpenHolderDialog}
        holder_name={selectedHolderName}
        cnpj={selectedCNPJ}
        appUser={_appUser}
      />
      <CreateHolderDialog
        open={opencreateHolderDialog}
        setOpen={setOpenCreateHolderDialog}
      />
    </Box>
  );
}
