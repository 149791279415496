// React
import React from 'react';

// Design
import { ThemeProvider } from '@mui/styles';
import CssBaseline from "@mui/material/CssBaseline";
import theme from './theme.js';

// Internal
import StatusVMSTable from './Pages/StatusVMS';
import { persistor } from './store';
import { appUser } from './store/app/slice';
import SignIn from './Pages/Signin';
import DropletsHealth from './Pages/DropletsHealth/';
import Holders from './Pages/Holders';
import StatusServicesTable from './Pages/StatusServices';

// Third-party
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { useSelector } from 'react-redux';

function App() {

  const user = useSelector(appUser);
  const userIsAllowed = Boolean(user);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route
              path={"/droplets-health"}
              element={userIsAllowed ? <DropletsHealth /> : <Navigate to={`/`} /> }
            />
            <Route
              path={"/status"}
              element={userIsAllowed ? <StatusVMSTable /> : <Navigate to={`/`} /> }
            />
            <Route
              path={"/holders"}
              element={userIsAllowed ? <Holders /> : <Navigate to={`/`} /> }
            />
            <Route
              path={"/services"}
              element={userIsAllowed ? <StatusServicesTable /> : <Navigate to={`/`} /> }
            />
            <Route
              path={'/'} 
              element={userIsAllowed ? <Navigate to={`/status`} /> :  <SignIn />}
            />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  );
}

export default App;
