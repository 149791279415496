// React
import React, { useState } from 'react';

// Design
import { makeStyles } from '@mui/styles';
import { 
          Container, 
          Box, 
          Button, 
          TextField, 
          Link, 
          Typography 
        } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// Internal
import NotificationBar from '../../components/Feedback/notification';

// import { setNotificationBarOpen } from '../../store/app/slice';
import { signIn } from '../../store/app/slice';
import '../../assets/css/signin.css';
import VERSION from '../../version';


// Third-party
import { useDispatch } from 'react-redux';

// Styles
import styles from "../../assets/jss/Pages/signInStyle.js";
const useStyles = makeStyles(styles);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://clickservices.com.br/">
        Click2Chat - Monitor - 
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function Appversion() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      Versão {VERSION}
    </Typography>
  );
}


export default function SignIn() {
  const dispatch = useDispatch();
  const classes = useStyles();
  
  
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = (e) => {
    setShowPassword(!showPassword);
  }

  const handleLogin = (e) => {
    e.preventDefault();
    let pass = {
      u: user,
      p: password
    }
    dispatch(signIn(pass));
    ;
  };

  return (
    <React.Fragment>
      <NotificationBar />
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <div className={classes.logo}>
            <img src="/images/Logo.png" className="App-tipo" alt="logo" />
          </div>
          <form className={classes.form} noValidate onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              label="Usuário"
              name="user"
              onChange={event => setUser(event.currentTarget.value)}
              onLoad={event => setUser(event.currentTarget.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              id="password"
              onChange={event => setPassword(event.currentTarget.value)}
              autoComplete="current-password"
              onLoad={event => setUser(event.currentTarget.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        <Box>
          <Appversion />
        </Box>
      </Container>
    </React.Fragment>
  );
}