// React
import React, { useState, useEffect } from 'react';

// Design
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import { TextField, Stack, Box } from '@mui/material';

// Internal
import { appUser } from '../../../store/app/slice';

// Third-party
import { useSelector } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/pt-br';

moment.locale('pt-br');


export default function CreateEnvironmentDialog({ open, setOpen, cnpj }) {

  const _appUser = useSelector(appUser);
  const [name, setName] = useState('');
  const [supervisorsQtt, setSupervisorsQtt] = useState(0);
  const [operatorsQtt, setOperatorsQtt] = useState(0);


  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateHolder = () => {
    const holderObj = {
      cnpj,
      name,
      supervisorsQtt,
      operatorsQtt
    }
    axios({
      method: 'post',
      url:`https://interface.clickservices.com.br/auth/admin/company/create?token=${_appUser.token}`,
      responseType: 'json',
      data: holderObj
    })
    .then(_response => {})
    .catch(console)
    .finally(el => {
      handleClose()
    })
    // console.log({holderObj})
  }

  useEffect(() => {
    if (!open) {
      setName('');
      setSupervisorsQtt(0);
      setOperatorsQtt(0);
    }
    // eslint-disable-next-line
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: 'center',
          width: '400px'
        }}
      >
        <Box
          sx={{
            width: '380px'
          }}
        >
          <Typography textAlign={'center'} textTransform='uppercase'>
            Criar Ambiente
          </Typography>
          <Stack  direction="column" spacing={2} >
            <TextField 
              id="name" 
              label="Nome do Ambiente" 
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField 
              id="supervisors_qtt" 
              label="Quantidade de Supervisores"
              type="number"
              variant="outlined"
              value={supervisorsQtt}
              onChange={(e) => setSupervisorsQtt(e.target.value)}
            />
            <TextField 
              id="pass" 
              label="Quantidade de Operadores"
              type="number"
              variant="outlined"
              value={operatorsQtt}
              onChange={(e) => setOperatorsQtt(e.target.value)}
            />
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: 'flex-end'
            }}
          >
            <Stack direction="row">
              <Button color='error' onClick={handleClose}>
                Cancelar
              </Button>
              <Button
                disabled={!cnpj || !name || !operatorsQtt || !supervisorsQtt }
                onClick={handleCreateHolder}
              >
                Criar
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
