// React
import React, { useState, useEffect } from "react";

// Design
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Stack, Box } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Internal
import { appUser } from "../../../store/app/slice";

// Third-party
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

export default function CreateLineDialog({ open, setOpen, id }) {
  const _appUser = useSelector(appUser);
  const [type, setType] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateVM = () => {
    const vmObj = {
      id,
      type,
    };
    axios({
      method: "post",
      url: `https://interface.clickservices.com.br/auth/admin/company/vm/line?token=${_appUser.token}`,
      responseType: "json",
      data: vmObj,
    })
      .then((_response) => {})
      .catch(console)
      .finally((el) => {
        handleClose();
      });
    // console.log({holderObj})
  };

  useEffect(() => {
    if (!open) {
      setType("");
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "400px",
        }}
      >
        <Box
          sx={{
            width: "380px",
          }}
        >
          <Typography textAlign={"center"} textTransform="uppercase">
            Criar Fila
          </Typography>
          <Stack direction="column" spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={type}
                label="Tipo"
                onChange={(event) => setType(event.target.value)}
              >
                <MenuItem value={"both"}>Receptivo</MenuItem>
                <MenuItem value={"input"}>Ativo</MenuItem>
                <MenuItem value={"input-training"}>Ativo Maturação</MenuItem>
                <MenuItem value={"supervisor"}>Supervisor</MenuItem>
                {/* <MenuItem value={'extreme'}>Extrema</MenuItem> */}
              </Select>
            </FormControl>
          </Stack>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Stack direction="row">
              <Button color="error" onClick={handleClose}>
                Cancelar
              </Button>
              <Button disabled={!id || !type} onClick={handleCreateVM}>
                Criar
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
